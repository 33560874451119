@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: local("SF Pro Display"), url("./fonts/SFPRODISPLAYREGULAR.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: local("SF Pro Display"), url("./fonts/SFPRODISPLAYBOLD.woff") format("woff");
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'SF Pro Display', 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1, h2, h3, p, span, strong {
  font-family: 'SF Pro Display', 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
